import { createAction } from 'redux-actions';

export const toggleDrawer = createAction('DRAWER_TOGGLE');
export const toggleAuthModal = createAction('AUTH_MODAL_TOGGLE');
export const toggleSideMenuModal = createAction('SIDE_MENU_MODAL_TOGGLE');
export const setBlockerDetector = createAction('SET_BLOCKER_DETECTOR');
export const switchTab = createAction('SEARCH_FORM_SWITCH_TAB');
export const setSearchSort = createAction('SET_SEARCH_SORT');
export const showPopupHeader = createAction('SHOW_POPUP_HEADER');
export const hidePopupHeader = createAction('HIDE_POPUP_HEADER');
export const setCurrentCity = createAction('SET_CURRENT_CITY');
export const setCurrentCityIsDeferred = createAction(
  'SET_CURRENT_CITY_IS_DEFERRED'
);
export const setSearchValues = createAction('SET_SEARCH_VALUES');
export const setResetPopupHandler = createAction('SET_RESET_POPUP_HANDLER');
export const setLocationsResetButtonIsDisabled = createAction(
  'SET_LOCATIONS_RESET_BUTTON_IS_DISABLED'
);
export const setResetButtonIsDisabled = createAction(
  'SET_RESET_BUTTON_IS_DISABLED'
);
export const setChosenLocationNames = createAction('SET_CHOSEN_LOCATION_NAMES');
export const setCitiesList = createAction('SET_CITIES_LIST');
export const setCurrentState = createAction('SET_CURRENT_STATE');
export const setError = createAction('SET_ERROR');
